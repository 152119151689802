<template>
     <div>
        <div class="mobile-nav">
            <MobileNav :showNav="showNav" v-on:close-nav="showNav = false" :type="1"/>
        </div>
        <AppHeader :type="1" v-on:open-mobile-nav="showNav = !showNav"/>
        <div class="container">
            <div>
                <h3>Privacy Policy</h3>
            </div>
            <div>
                <b>Effective Date: August 21, 2024</b>
            </div>
            <div>
                <p>This Privacy Policy outlines how Linkmeup Inc. a Delaware USA corporation ( operating under the laws of the USA with its registered address at 1835, 1007 N Orange St. 4th Floor , Wilmington, DE, New Castle, US, 19801) collects, uses, discloses, and protects your personal information when you use our Access and Identity Management application. We comply with the Delaware Consumer Data Protection Act (CDPA) and other applicable laws and regulations.</p>
                <p>This Privacy Policy is part of our Terms of Use. By accessing or using our Services, website, software, services, applications, products and content offered via Linkmeup Inc. (collectively, the “Services” referred also as “LMU”), you agree to this Privacy Policy and our Terms of Use.</p>
            </div>
            <div>
                <p>
                    <b>Information We Collect</b>
                </p>
                <p><b>Personal Information:</b> We may collect the following personal information from you:</p>
            </div>
            <div>
                <ul>
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Phone number</li>
                    <li>Date of birth</li>
                    <li>Job title (for business users)</li>
                    <li>Company information (for business users)</li>
                    <li>Residential Information (for residence user)</li>
                    <li>Student Information (for school user)</li>
                    <li>Access credentials (e.g., usernames, passwords)</li>
                    <li>Usage data (e.g., login history, application usage)</li>
                    <li>Identity Data & Identification Documents:  Identification documents may include passport or any Government-issued identity card, a photograph (if applicable) and any other registration information you may provide to prove you are eligible to use our services. This data is to enable us to verify your identity in order to offer our services to you;</li>
                </ul>
            </div>

            <div>
                <b>Technical Data:</b> We may automatically collect technical information about your device and your interactions with our application (Web, Android and iOS). The following permissions need to be granted in order to access all the features of the application: 
            </div>

            <div>
                <ul>
                    <li>IP address</li>
                    <li>Browser type and version</li>
                    <li>Operating system</li>
                    <li>Device identifiers</li>
                    <li>Read phone status and identity: Autofill your phone number when user register for LMU for user convenience.</li>
                    <li>Receive text messages (SMS): Autofill the SMS code that LMU sends to user during phone number verification, for your convenience.</li>
                    <li>Access to camera: User will use camera to scan and QR codes and pictures for user profile.</li>
                    <li>Access to calendar: to save appointments and set reminders. </li>
                    <li>Notifications: Receive notifications when other user send or confirm appointments, and other important event reminders.</li>
                    <li>Full network access: Send, receive, and confirm appointment, and other data.</li>
                    <li>Phone contacts: Access to read and write user contacts: We may collect and use information from your device's address book so user can invite and add friends, so we can improve the app experience.</li>
                    <li>GPS: Access to precise location (GPS and network-based): Use your location for features like Geo filters to add and confirm addresses to appointments created, and for other services that improve user experience</li>
                    <li>Media: Access to phone media including documents and pictures and videos</li>
                    <li>Control flashlight: Use your flash to scan QR codes in the dark.</li>
                    <li>View Network Connections: LMU optimizes data delivery based on the strength of user internet connection.</li>
                    <li>Clipboard: Allow LMU to access user clipboard so you can attach links quickly with the Paperclip tool.</li>
                    <li>Change your audio settings: On some devices, LMU adjusts audio settings to let user set notification/alert tune.</li>
                    <li>Prevent phone from sleeping: user screen won't automatically turn off while using LMU.</li>
                    <li>Control vibration: Allows you to enable vibration for notifications.</li>
                    <li>View Wi-Fi Connections: LMU optimizes data quality based on the strength of your Wi-Fi connection.</li>
                    <li>Change network connectivity: Connect directly to Spectacles via Wi-Fi.</li>
                    <li>Connect & disconnect from Wi-Fi</li>
                    <li>Read your own contact card: Auto fill User full name when user edit it in</li>
                    <li>LMU settings, for user convenience.</li>
                </ul>
            </div>


            <div>
                <b>How We Use Your Information</b>
            </div>
            <div>We use your information to:</div>

            <div>
                <ul>
                    <li>Provide, maintain, and improve our Access and Identity Management application.</li>
                    <li>Process your registration and manage your account.</li>
                    <li>Authenticate your access to the application.</li>
                    <li>Provide customer support and assistance.</li>
                    <li>Analyze usage patterns and trends to improve our services (after obtaining your consent, if required by CDPA).</li>
                    <li>Protect the security of our application and your data.</li>
                    <li>Comply with legal and regulatory requirements.</li>
                    <li>We will not use your personal information for any purpose that is not listed above without your consent.</li>
                </ul>
            </div>

            <div>
                <p>
                    <b> Disclosure of Your Information</b>
                </p>
                
                <div>We may disclose your personal information to:</div>
                <ul>
                    <li>Our affiliates and subsidiaries.</li>
                    <li>Third-party service providers who assist us in providing our services (e.g., cloud providers, payment processors) under contractual agreements that prohibit them from using your information for any other purpose.</li>
                    <li>Law enforcement agencies or regulatory bodies as required by law.</li>
                </ul>
                <p>We will only disclose your personal information to third parties with your consent, unless required by law.</p>
            </div>

            <div>
                <p>
                    <b>Data Security</b>
                </p>
            <p> We implement reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.</p>   
                <p>
                    <b>Your Rights under the Delaware CDPA</b>
                </p>
                <p>You have certain rights regarding your personal information under the CDPA, including the right to:</p>
                <ul>
                    <li>Access your personal information.</li>
                    <li>Correct inaccurate personal information.</li>
                    <li>Delete your personal information.</li>
                    <li>Obtain a portable copy of your personal information.</li>
                    <li>Opt-out of the sale of your personal information (we do not currently sell personal information).</li>
                </ul>
            </div>
            <div>
                To exercise your rights, please contact us using the information provided below.
            </div>
            <div>
                <p>
                    <b>Changes to This Privacy Policy</b>
                </p>
                <p>
                    We may update this Privacy Policy from time to time. We will notify you of any material changes by posting a notice on our website or by contacting you directly.  
                </p>
            </div>

            <div>
                <p>
                    <b>Contact Us</b>
                </p>
                <p>If you have any questions about this Privacy Policy or our practices, please contact us at:</p>
                <p> Linkmeup Inc. </p>
                <p>1835, 1007 N Orange St. 4th Floor , Wilmington, DE, New Castle, US, 19801</p>
                <p>
                    <a href="mailto:support@linkmeup.us" target="_blank">support@linkmeup.us </a>
                </p>
                <p>
                    <a href="tel:+1(302)440-3393">+1 (302) 440-3393</a>
                </p>
            </div>

        </div>
     </div>
</template>

<script>
import AppHeader from '../../components/Header.vue'
import MobileNav from '../../components/MobileNav.vue'

export default {
    components: {
        AppHeader,
        MobileNav
    },
    data(){
        return {
            showNav: false
        }
    }
}
</script>

<style scoped>
    div, p {
        margin-bottom: 20px;
    }

    ul li {
        margin-bottom: 10px;
    }
</style>