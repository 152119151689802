<template>
  <header>
      <div class="container">
          <div class="main-header">
            
            
            <div class="logo flex-align">
                <div class="nav-icon" v-show="mobileView" @click="$emit('open-mobile-nav')">
                    <i class="fa fa-bars"></i>
                </div>
               <div>
                    <router-link :to="type == 1 ? '/' : '/security'"><img src="../assets/images/pri_logo.png" alt="logo" width="30" style="margin-right: 7px" v-if="type == 1">
                    <img src="../assets/images/sec_logo.png" alt="logo" width="50" style="margin-right: 7px" v-else>
                    <h2 :class="type == 1 ? 'pri-color' : 'sec-color'">Linkmeup <span v-if="type !== 1">Security</span></h2></router-link>
               </div>
            </div>

            <div class="links" :class="type == 1 ? 'individual' : 'security'" v-if="type == 1" v-show="!mobileView">
                <ul>
                    <li>
                        <a href="#home" v-smooth-scroll>Home</a>
                    </li>
                    <li>
                        <router-link to="/security">Security</router-link>
                    </li>
                    <li>
                        <a href="#api" v-smooth-scroll>API</a>
                    </li>
                    <li>
                        <a href="#about" v-smooth-scroll>About us</a>
                    </li>
                    <li>
                        <a href="#feedback" v-smooth-scroll>Contact us</a>
                    </li>
                    <li>
                        <a href="https://medium.com/@Linkmeup_IAM" target="_blank">Blog</a>
                    </li>
                </ul>
            </div>

            <div class="links" :class="type == 1 ? 'individual' : 'security'" v-if="type == 2" v-show="!mobileView">
                <ul>
                    <li>
                        <router-link to="/">Home</router-link>
                    </li>
                    <li>
                        <router-link to="/security">Security</router-link>
                    </li>
                    <li>
                        <a href="#qr-code" v-smooth-scroll>QR Code Kit</a>
                    </li>
                    <li>
                        <a href="#subscription" v-smooth-scroll>Subscription</a>
                    </li>
                    <li>
                        <a href="https://medium.com/@Linkmeup_IAM" target="_blank">Blog</a>
                    </li>
                </ul>
            </div>

            <div class="extras" v-if="type == 1" v-show="!mobileView">
                <div class="lang flex">
                    <p class="flex">
                        <div class="lang-flag">
                            <img src="https://upload.wikimedia.org/wikipedia/en/thumb/a/a4/Flag_of_the_United_States.svg/2560px-Flag_of_the_United_States.svg.png" alt="flag" width="39">
                        </div>
                        En
                    </p> 
                    <i class="fa fa-angle-down"></i>
                </div>
            </div>

            <div class="auth flex" v-else v-show="!mobileView">
                <div class="signup">
                    <Button @click="$router.push('/security/signup')" :style="{ color: '#25A244', backgroundColor: '#fff', width: '100px' }" text="Signup"  class="auth-btn"/>
                </div>
                <div class="login">
                    <Button @click="$router.push('/security/login')" :style="{ color: '#FFF', backgroundColor: '#FFCB00', width: '100px' }" text="Login" class="auth-btn"/>
                </div>
            </div>
          </div>
      </div>
  </header>
</template>

<script>
import Button from './Button.vue'

export default {
    props: ['type'],
    components: {
        Button,
    },
     data(){
        return {
            mobileView: true,
            showNav: false
        }
    },
    methods: {
        handleView() {
            this.mobileView = window.innerWidth <= 990;
        },
    },
    created(){
        this.handleView();
        window.addEventListener('resize', this.handleView);
    }
}
</script>

<style scoped>
    header {
        padding: 20px;
    }

    header h2 {
        font-weight: 700;
        font-family: 'poppins', sans-serif;
    }

    .logo a {
        display: flex;
        align-items: center;
    }

    .main-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
    }

    .links ul li {
        display: inline-block
    }
    
    .links li {
        list-style: none;
    }

    a {
        text-decoration: none;
        color: #000;
        font-family: 'poppins';
        /* font-weight: bold; */
    }

    .links a {
        padding: 10px;
    }

    .individual a:hover {
        color: #093AC9;
    }

    .security a:hover {
        color: #159661;
    }

    .extras >  div {
        padding: 10px;
        border-radius: 5px;
        font-size: 12px;
        width: 100px;
        cursor: pointer;
        box-shadow: 0 0 2px 0 #d6d6d6;
    }

    .lang-flag {
        height: 20px;
        width: 20px;
        border-radius: 100px;
        overflow: hidden;
    }

    .lang-flag img {
        object-fit: contain;
    }

    .sec-color {
        color: #159661;
    }

    .pri-color {
        color: #093AC9;
    }

    .nav-icon {
        margin-right: 1rem;
        font-size: 1.5rem;
        padding: 10px;
    }

    @media(max-width:769px) {
        header{
            padding: 10px;
            position: fixed;
            background: #fff;
            width: 100%;
            z-index: 10000;
            box-shadow: 0 0 6px 3px #38383843;
            top: 0;
            left: 0;
            /* box-shadow: 0 0 6px 4px #000; */
        }

        header h2 {
            font-weight: 600;
        }
    }
</style>