<template>
  <div class="mobile-nav" :style="showNav ? 'width: 300px' : 'width: 0; overflow: hidden; padding: 0'">
      <span class="close-btn" @click="$emit('close-nav')">
          <i class="fa fa-times"></i>
      </span>

        <div class="links" :class="type == 1 ? 'individual' : 'security'" v-if="type == 1">
            <ul>
                <li>
                    <a href="#home" v-smooth-scroll>Home</a>
                </li>
                <li>
                    <router-link to="/security">Security</router-link>
                </li>
                <li>
                    <a href="#api" v-smooth-scroll>API</a>
                </li>
                <li>
                    <a href="#about" v-smooth-scroll>About us</a>
                </li>
                <li>
                    <a href="#feedback" v-smooth-scroll>Contact us</a>
                </li>
                <li>
                    <a href="https://medium.com/@Linkmeup_IAM" target="_blank">Blog</a>
                </li>
            </ul>
        </div>

        <div class="links" :class="type == 1 ? 'individual' : 'security'" v-if="type == 2">
            <ul>
                <li>
                    <router-link to="/">Home</router-link>
                </li>
                <li>
                    <router-link to="/security">Security</router-link>
                </li>
                <li>
                    <a href="#qr-code" v-smooth-scroll>QR Code Kit</a>
                </li>
                <li>
                    <a href="#subscription" v-smooth-scroll>Subscription</a>
                </li>
                <li>
                    <a href="https://medium.com/@Linkmeup_IAM" target="_blank">Blog</a>
                </li>
            </ul>
        </div>

        <div class="auth flex" v-if="type == 2">
            <div class="signup">
                <Button @click="signup" :style="{ color: '#25A244', backgroundColor: '#fff', width: '100px' }" text="Signup"  class="auth-btn"/>
            </div>
            <div class="login">
                <Button @click="login" :style="{ color: '#FFF', backgroundColor: '#FFCB00', width: '100px' }" text="Login" class="auth-btn"/>
            </div>
        </div>
    </div>
</template>

<script>
import Button from './Button.vue'

export default {
    props: ['showNav', 'type'],
    components: {
        Button,
    },
    methods: {
        login(){
            this.$emit('close-nav')
            this.$router.push('/security/login')
        },
        signup() {
            this.$emit('close-nav')
            this.$router.push('/security/signup')
        }
    }
}
</script>

<style scoped>
    .mobile-nav {
        padding: 20px;
        position: fixed;
        background-color: rgba(0, 0, 0, 0.789);
        top: 0;
        height: 100%;
        left: 0;
        z-index: 100000;
        overflow: hidden;
        transition: 0.3s ease-in
    }

    .mobile-nav  ul li a {
        display: block;
        color: #fff;
        text-decoration: none;
        padding: 10px;
        font-weight: 600;
    }

    ul li {
        list-style: none;
    }

    ul li ul {
        margin-bottom: 20px;
        padding: 20px;
    }

    ul li ul a {
        padding: 10px;
    }

    .fa-caret-down {
        color: #FFCB00;
    }

    .close-btn {
        color: #fff;
        float: right;
        /* margin-bottom: 0px; */
    }

    .links {
        clear: both;
    }

    .security {
        margin-bottom: 7rem;
    }
    
</style>